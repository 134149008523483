import { FormControl } from '@angular/forms';

export const ValidatePassword = (control: FormControl) => {
  const password = control.value;
  const form = control.parent;
  const pwdHasLowerLetter = password && /[a-z]/.test(password) ? true : false;
  const pwdHasUperLetter = password && /[A-Z]/.test(password) ? true : false;
  const pwdValidLength =
    password && password.length >= 8 && password.length <= 20 ? true : false;
  const pwdhasNumber = password && /[0-9]/.test(password) ? true : false;
  const pwdValidSpecial =
    password &&
    /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/.test(
      password,
    )
      ? true
      : false;

  const pwdValidNoInputName =
    password &&
    !(
      password.includes(
        form.controls['firstName'].value
          ? form.controls['firstName'].value
          : null,
      ) ||
      password.includes(
        form.controls['lastName'].value
          ? form.controls['lastName'].value
          : null,
      )
    );

  const valid =
    pwdHasLowerLetter &&
    pwdHasUperLetter &&
    pwdhasNumber &&
    pwdValidSpecial &&
    pwdValidLength &&
    pwdValidNoInputName;

  if (!valid) {
    return {
      pwdHasLowerLetter,
      pwdHasUperLetter,
      pwdhasNumber,
      pwdValidSpecial,
      pwdValidLength,
      pwdValidNoInputName,
    };
  }
  return null;
};
